import React, { useEffect, useState, useContext } from "react";

import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Container,
  Button,
  InputAdornment,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@material-ui/core";
import OwnerHeader from "./Components/OwnerHeader";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import GlobalContext from "../../Context/globalContext";
import Chip from "@material-ui/core/Chip";

import SearchIcon from "@material-ui/icons/Search";
import StorefrontIcon from "@material-ui/icons/Storefront";
import { CloseIcon, CloudUploadIcon, DeleteOutline } from "@material-ui/icons";

import { Instance } from "../../Config";
import { SHOW_SNACKBAR } from "../../Context/globalActions.types";

import jsPDF from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";
import useSWR from "swr";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import { Add } from "@material-ui/icons";
import IOSSwitch from "../../Components/IOSSwitch";

const OwnerViewApprovals = () => {
  const [search, setSearch] = useState("");

  const [isLoading, setIsLoading] = useState({
    uniqueNo: "",
    state: "",
    accCd: "",
  });

  const [filterAccount, setFilterAccount] = useState([]);

  const [rows, setRows] = useState([]);
  const history = useHistory();
  const params = useParams();

  const { globalContext, dispatch } = useContext(GlobalContext);

  const [formValue, setFormValue] = useState({
    accCd: "",
    uniqueNo: "",
    modelName: "",
    deviceName: "",
    isApproved: true,
    mobileNo: params.mobileNo,
  });

  const [formError, setFormError] = useState({
    message: null,
    type: null,
  });

  const [isUploading, setIsUploading] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const id = open1 ? "simple-popover" : undefined;

  const location = useLocation();
  function getQueryVariable(variable) {
    var query = location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  }

  const [open11, setOpen11] = React.useState(false);

  const handleClickOpen1 = () => {
    setFormValue({
      accCd: "",
      deviceName: "",
      uniqueNo: "",
      isApproved: true,
      modelName: "",
      mobileNo: params.mobileNo,
    });
    setIsUploading(false);
    setFormError({
      message: null,
      type: null,
    });
    setOpen11(true);
  };

  const handleClose1 = () => {
    setOpen11(false);
  };

  const handleSearch = (search) => {
    if (search != "") {
      setRows([]);
      Instance.get(`/approval?mobileNo=${params.mobileNo}&query=${search}`)
        .then((res) => {
          if (res.status == 200) {
            setRows(res.data.result);
          }
        })
        .catch((e) => {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              message: e.response.message || "Something went wrong",
              type: "error",
            },
          });
        });
    } else {
      Instance.get(`/approval?mobileNo=${params.mobileNo}`)
        .then((res) => {
          if (res.status == 200) {
            setRows(res.data.result);
          }
        })
        .catch((e) => {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              message: e.response.message || "Something went wrong",
              type: "error",
            },
          });
        });
    }
  };

  const submit = (accCd, uniqueNo, mobileNo, isApproved) => {
    Instance.put("/approval/verify", {
      accCd,
      uniqueNo,
      mobileNo,
      isApproved,
    })
      .then((res) => {
        if (res.status == 200) {
          setIsLoading({
            uniqueNo: "",
            state: "",
            accCd: "",
          });
          mutateOwnerApproval();
        }
      })
      .catch((e) => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: e.response.message || "Something went wrong",
            type: "error",
          },
        });
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formValue.accCd == "") {
      setFormError({
        message: "Please Enter Valid Party Code",
        type: "accCd",
      });
      return;
    }
    if (formValue.uniqueNo == "") {
      setFormError({
        message: "Please Enter Valid Unique Id",
        type: "uniqueNo",
      });
      return;
    }
    setFormError({
      message: null,
      type: null,
    });
    setIsUploading(true);

    Instance.post("/approval", formValue)
      .then(() => {
        handleClose1();
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: "Data Inserted successfully",
            type: "success",
          },
        });
        mutateOwnerApproval();
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: "Something went wrong",
            type: "error",
          },
        });
      });
  };

  const handleDelete = (accCd, uniqueNo, mobileNo) => {
    Instance.delete(
      `/approval?accCd=${accCd}&uniqueNo=${uniqueNo}&mobileNo=${mobileNo}`
    )
      .then(() => {
        setIsLoading({
          uniqueNo: "",
          state: "",
          accCd: "",
        });
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: "Data Deleted successfully",
            type: "success",
          },
        });
        mutateOwnerApproval();
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: "Something went wrong",
            type: "error",
          },
        });
      });
  };

  const {
    data: resOwnerApprovals,
    error: resOwnerApprovalsError,
    mutate: mutateOwnerApproval,
  } = useSWR(
    getQueryVariable("query")
      ? `/approval?mobileNo=${params.mobileNo}&query=${getQueryVariable(
          "query"
        )}`
      : `/approval?mobileNo=${params.mobileNo}`
  );

  const { data: resFilterAccounts, error: resFilterAccountsError } = useSWR(
    `/account/getFilterAccounts?mobileNo=${params.mobileNo}`
  );

  useEffect(() => {
    if (resOwnerApprovals) {
      setRows(resOwnerApprovals.data.result);
    }
  }, [resOwnerApprovals]);

  useEffect(() => {
    if (resFilterAccounts) {
      setFilterAccount(resFilterAccounts.data.result);
    }
  }, [resFilterAccounts]);

  useEffect(() => {
    if (resOwnerApprovalsError || resFilterAccountsError) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          message: "Something went wrong",
          type: "error",
        },
      });
    }
  }, [resOwnerApprovalsError, resFilterAccountsError]);

  return (
    <div>
      <OwnerHeader>
        <AddDialog
          open={open11}
          handleClose={handleClose1}
          formValue={formValue}
          setFormValue={setFormValue}
          isUploading={isUploading}
          handleSubmit={handleSubmit}
          formError={formError}
          filterAccount={filterAccount}
        />
        <div className="md:flex items-center justify-between px-3 md:px-4 py-3">
          <div>
            <div className="text-indigo-500 font-semibold text-sm md:text-base">
              Store Management
            </div>
            <div className="text-xl md:text-2xl font-extrabold leading-7 sm:leading-10 truncate mt-1">
              User Approval
            </div>
          </div>
          {/* <div className="flex items-center mb-3 mt-2 md:mt-0">
            <Input
              placeholder="Search"
              className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg"
              disableUnderline
              value={search}
              onChange={(e) => {
                if (e.target.value == "") {
                  setSearch("");
                } else {
                  setSearch(e.target.value);
                }
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
            <Button
              variant="contained"
              disableElevation
              className="ml-2 bg-[#3333cc] text-white"
              onClick={() => {
                history.push(
                  `/${params.mobileNo}/owner/userapproval?query=${search}`
                );
                handleSearch(search);
              }}
            >
              Search
            </Button>
          </div> */}
        </div>
        <Container
          maxWidth="xl"
          className="pt-2 bg-[#F1F5F9] pb-5 min-h-screen"
        >
          <div>
            <div>
              {/* <div className="flex justify-end mb-3">
                <Button
                  variant="contained"
                  disableElevation
                  className="ml-2 bg-[#3333cc] text-white"
                  onClick={handleClickOpen1}
                  startIcon={<Add />}
                >
                  Create
                </Button>
              </div> */}

              {!resOwnerApprovals && rows.length === 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
                  {[
                    "A",
                    "B",
                    "C",
                    "D",
                    "E",
                    "F",
                    "G",
                    "H",
                    "I",
                    "J",
                    "K",
                    "L",
                  ].map((item) => (
                    <div key={item}>
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width={350}
                        height={118}
                      />
                    </div>
                  ))}
                </div>
              ) : resOwnerApprovals && rows.length == 0 ? (
                <div className="min-h-screen text-center">
                  <StorefrontIcon className="text-black text-[12rem]" />
                  <Typography className="text-2xl font-bold text-black">
                    Oops!
                  </Typography>
                  <Typography>No Match Found.</Typography>
                </div>
              ) : (
                <React.Fragment>
                  <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
                    {rows.map((row, index) => {
                      return (
                        <div
                          className="border-[.5px] text-black border-[#3333cc] rounded-lg p-2  items-center bg-white text-sm"
                          key={index}
                        >
                          <div>
                            <div className="mr-1">
                              <span>
                                Acc Cd :
                                <span className="font-bold">
                                  {" "}
                                  {row.ACC_CD ? row.ACC_CD : ""}
                                </span>
                              </span>
                            </div>

                            <div>
                              Acc Name :
                              <span className="font-bold capitalize">
                                {" "}
                                {row.account
                                  ? row.account.ACC_NAME.toLowerCase()
                                  : "N.A"}
                              </span>
                            </div>

                            <div>
                              Mobile No :
                              <span className="font-bold">
                                {" "}
                                {row.account ? row.account.PHONE1 : "N.A"}
                              </span>
                            </div>

                            <div>
                              City :
                              <span className="font-bold capitalize">
                                {" "}
                                {row.account
                                  ? row.account.CITY.toLowerCase()
                                  : "N.A"}
                              </span>
                            </div>

                            <div>
                              Model :
                              <span className="font-bold">
                                {" "}
                                {row.MODEL_NAME ? row.MODEL_NAME : "N.A"}
                              </span>
                            </div>

                            <div>
                              Device :
                              <span className="font-bold">
                                {" "}
                                {row.DEVICE_NAME ? row.DEVICE_NAME : "N.A"}
                              </span>
                            </div>

                            <div>
                              Unique Id :
                              <span className="font-bold">
                                {" "}
                                {row.UNIQUE_NO ? row.UNIQUE_NO : "N.A"}
                              </span>
                            </div>

                            <div>
                              Status :
                              <span className="font-bold">
                                {" "}
                                {row.IS_APPROVED ? (
                                  row.IS_APPROVED === "Y" ? (
                                    <span className="bg-green-500 text-white py-0.5 px-1 rounded">
                                      Approved
                                    </span>
                                  ) : (
                                    <span className="bg-red-500 text-white py-0.5 px-1 rounded">
                                      Deny
                                    </span>
                                  )
                                ) : (
                                  "N.A"
                                )}
                              </span>
                            </div>

                            {row.IS_APPROVED === null ? (
                              <div className="flex justify-between mt-2">
                                {isLoading.uniqueNo === row.UNIQUE_NO &&
                                isLoading.accCd === row.account.ACC_CD &&
                                isLoading.state ? (
                                  <CircularProgress
                                    size={24}
                                    className="ml-2 mt-2"
                                  />
                                ) : (
                                  <Button
                                    variant="contained"
                                    disableElevation
                                    className="ml-2 bg-green-600 text-white"
                                    onClick={() => {
                                      setIsLoading({
                                        uniqueNo: row.UNIQUE_NO,
                                        state: true,
                                        accCd: row.account.ACC_CD,
                                      });

                                      submit(
                                        row.account.ACC_CD,
                                        row.UNIQUE_NO,
                                        row.MOBNOCO,
                                        true
                                      );
                                    }}
                                  >
                                    Approve
                                  </Button>
                                )}

                                {isLoading.uniqueNo === row.UNIQUE_NO &&
                                isLoading.accCd === row.account.ACC_CD &&
                                !isLoading.state ? (
                                  <CircularProgress
                                    size={24}
                                    className="ml-2 mt-2"
                                  />
                                ) : (
                                  <Button
                                    variant="contained"
                                    disableElevation
                                    className="mr-2 bg-red-600 text-white"
                                    onClick={() => {
                                      setIsLoading({
                                        uniqueNo: row.UNIQUE_NO,
                                        state: false,
                                        accCd: row.account.ACC_CD,
                                      });

                                      submit(
                                        row.account.ACC_CD,
                                        row.UNIQUE_NO,
                                        row.MOBNOCO,
                                        false
                                      );
                                    }}
                                  >
                                    Deny
                                  </Button>
                                )}
                              </div>
                            ) : isLoading.uniqueNo === row.UNIQUE_NO &&
                              isLoading.accCd === row.account.ACC_CD ? (
                              <div className="flex justify-center mt-2">
                                <CircularProgress
                                  size={24}
                                  className="ml-2 mt-2"
                                />
                              </div>
                            ) : (
                              <div className="flex justify-center mt-2">
                                <Button
                                  variant="contained"
                                  disableElevation
                                  className="mr-2 bg-red-600 text-white"
                                  onClick={() => {
                                    setIsLoading({
                                      uniqueNo: row.UNIQUE_NO,
                                      state: "",
                                      accCd: row.account.ACC_CD,
                                    });
                                    handleDelete(
                                      row.account.ACC_CD,
                                      row.UNIQUE_NO,
                                      row.MOBNOCO
                                    );
                                  }}
                                >
                                  Delete
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </Container>
      </OwnerHeader>
    </div>
  );
};

const AddDialog = ({
  open,
  handleClose,
  handleSubmit,
  formValue,
  setFormValue,
  isUploading,
  formError,
  filterAccount,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">Add Data</DialogTitle>
      <DialogContent>
        <div>
          <div className="font-bold">Acc Cd:</div>
          <Autocomplete
            id="Party Code"
            autoHighlight
            options={filterAccount}
            getOptionLabel={(option) => option.ACC_NAME}
            renderOption={(option) => (
              <React.Fragment>{option.ACC_NAME}</React.Fragment>
            )}
            className="md:w-52 w-full"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                label="Party Code"
                margin="normal"
              />
            )}
            onChange={(e, value) => {
              console.log(value);
              if (value) {
                setFormValue({
                  ...formValue,
                  accCd: value.ACC_CD,
                });
              }
            }}
          />
          {formError.type == "accCd" ? (
            <div className="text-red-500 text-xs mt-1">{formError.message}</div>
          ) : null}
        </div>
        <div className="mt-2">
          <div className="font-bold">Unique Id:</div>
          <Input
            className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
            disableUnderline
            value={formValue.uniqueNo}
            onChange={(e) => {
              setFormValue({ ...formValue, uniqueNo: e.target.value });
            }}
          />
          {formError.type == "uniqueNo" ? (
            <div className="text-red-500 text-xs mt-1">{formError.message}</div>
          ) : null}
        </div>

        <div className="mt-2">
          <div className="font-bold">Model:</div>
          <Input
            className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
            disableUnderline
            value={formValue.modelName}
            onChange={(e) => {
              setFormValue({ ...formValue, modelName: e.target.value });
            }}
          />
          {formError.type == "modelName" ? (
            <div className="text-red-500 text-xs mt-1">{formError.message}</div>
          ) : null}
        </div>

        <div className="mt-2">
          <div className="font-bold">Device:</div>
          <Input
            className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
            disableUnderline
            value={formValue.deviceName}
            onChange={(e) => {
              setFormValue({ ...formValue, deviceName: e.target.value });
            }}
          />
          {formError.type == "deviceName" ? (
            <div className="text-red-500 text-xs mt-1">{formError.message}</div>
          ) : null}
        </div>

        <div className="flex justify-between mt-3">
          <div>Approve User: </div>
          <IOSSwitch
            handleChange={() => {
              setFormValue({
                ...formValue,
                isApproved: !formValue.isApproved,
              });
            }}
            name={formValue.accCd}
            isChecked={formValue.isApproved}
          />
        </div>
      </DialogContent>
      <DialogActions>
        {!isUploading ? (
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        ) : null}
        <Button onClick={handleSubmit} disabled={isUploading} color="primary">
          {isUploading ? <CircularProgress size={20} /> : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OwnerViewApprovals;
