import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Grid,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Divider,
  Input,
  InputAdornment,
} from "@material-ui/core";
import { Instance } from "../Config";
import GlobalContext from "../Context/globalContext";
import {
  INIT_COMPANY_INFO,
  INIT_SETTINGS,
  INIT_OWNER,
  SIGN_IN,
  SHOW_SNACKBAR,
} from "../Context/globalActions.types";
import {
  useParams,
  useHistory,
  Redirect,
  useLocation,
  Link,
} from "react-router-dom";
import CustomSnakBar from "../Components/CustomSnakBar";
import { decode } from "jsonwebtoken";
import useWindowSize from "../LayoutHook";
import LoginDrawer from "../Components/LoginDrawer";
import useSWR from "swr";
import SearchIcon from "@material-ui/icons/Search";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

const Login = () => {
  //Error state
  const [isUserNameValid, setIsUserNameValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [width] = useWindowSize();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const params = useParams();
  const history = useHistory();
  let mobileNo = params.mobileNo;

  const [isLoading, setisLoading] = useState(false);
  const [isGuestLoading, setIsGuestLoading] = useState(false);

  const [isFetching, setIsFetching] = useState(true);

  const { globalContext, dispatch } = useContext(GlobalContext);

  const { data: resOwnerDetail, error: resOwnerDetailError } = useSWR(
    `/owner?mobileNo=${params.mobileNo}`
  );

  const { data: resSettings, error: resSettingsError } = useSWR(
    `/settings?mobileNo=${params.mobileNo}`
  );

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [formMobileNo, setFormMobileNo] = useState("");

  useEffect(() => {
    if (resOwnerDetail) {
      dispatch({
        payload: resOwnerDetail.data.result,
        type: INIT_OWNER,
      });
    }
  }, [resOwnerDetail]);

  useEffect(() => {
    if (resSettings) {
      setIsFetching(false);
      if (resSettings.data.result.length == 1) {
        dispatch({
          type: INIT_SETTINGS,
          payload: [],
        });
      } else {
        dispatch({
          type: INIT_SETTINGS,
          payload: resSettings.data.result,
        });
      }
    }
  }, [resSettings]);

  const handleGuestLogin = (e) => {
    e.preventDefault();
    setIsGuestLoading(true);
    Instance.post("/auth/guest/", { mobileNo })
      .then((res) => {
        if (res.status == 200) {
          if (res.data.role == "user") {
            setIsGuestLoading(false);
            dispatch({
              payload: res.data.token,
              type: SIGN_IN,
            });
            // window.location.reload();
            localStorage.setItem("user", res.data.token);
            history.push(`/${mobileNo}`);
          } else {
            setisLoading(false);
            setIsGuestLoading(false);
            dispatch({
              type: SHOW_SNACKBAR,
              payload: {
                message: "Your are not authenticate to this area.",
                type: "error",
              },
            });
          }
        }
      })
      .catch((e) => {
        setisLoading(false);
        setIsGuestLoading(false);
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: e.response.data.message || "Something went wrong",
            type: "error",
          },
        });
      });
  };

  const location = useLocation();
  function getQueryVariable(variable) {
    var query = location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setisLoading(true);
    if (userName !== "") {
      if (password !== "") {
        setIsUserNameValid(true);
        setIsPasswordValid(true);
        Instance.post("/auth/", {
          userName,
          password,
          mobileNo,
          uniqueNo: localStorage.getItem("unique_no"),
          modelName: "Google Chrome",
          deviceName: "Web Browser",
        })
          .then((res) => {
            if (res.status == 200) {
              if (res.data.role == "owner") {
                dispatch({
                  payload: res.data.token,
                  type: SIGN_IN,
                });
                localStorage.setItem("user", res.data.token);
                if (
                  getQueryVariable("continue") &&
                  getQueryVariable("continue").split("/")[2] == "owner"
                ) {
                  history.push(getQueryVariable("continue"));
                  // window.location.reload();
                } else {
                  history.push(`/${mobileNo}/owner`);
                  // window.location.reload();
                }
              } else if (res.data.role == "user") {
                if (res.data.uniqueNo) {
                  localStorage.setItem("unique_no", res.data.uniqueNo);
                }

                if (!res.data.isVerified) {
                  dispatch({
                    type: SHOW_SNACKBAR,
                    payload: {
                      message:
                        "Your account is not Verified. Please Contact the Admin.",
                      type: "error",
                    },
                  });
                  setisLoading(false);
                } else {
                  dispatch({
                    payload: res.data.token,
                    type: SIGN_IN,
                  });
                  localStorage.setItem("user", res.data.token);
                  if (
                    getQueryVariable("continue") &&
                    getQueryVariable("continue").split("/")[2] != "owner"
                  ) {
                    history.push(getQueryVariable("continue"));
                    // window.location.reload();
                  } else {
                    history.push(`/${mobileNo}`);
                    // window.location.reload();
                  }
                }
              } else {
                setisLoading(false);
                dispatch({
                  type: SHOW_SNACKBAR,
                  payload: {
                    message: "Your are not authenticate to this area.",
                    type: "error",
                  },
                });
              }
            }
          })
          .catch((e) => {
            setisLoading(false);
            dispatch({
              type: SHOW_SNACKBAR,
              payload: {
                message: e.response.data.message || "Something went wrong",
                type: "error",
              },
            });
          });
      } else {
        setisLoading(false);
        setIsPasswordValid(false);
        setIsUserNameValid(true);
      }
    } else {
      setisLoading(false);
      setIsUserNameValid(false);
      setIsPasswordValid(true);
    }
  };

  const { data: resAbout, error: resAboutError } = useSWR(
    `/saral/about?mobileNo=${params.mobileNo}`
  );

  useEffect(() => {
    if (resAbout) {
      if (resAbout.data.result.length != 0) {
        dispatch({
          type: INIT_COMPANY_INFO,
          payload: resAbout.data.result,
        });
      } else {
        dispatch({
          type: INIT_COMPANY_INFO,
          payload: [{ COMP_NAME: "", PERSON_NM: "", EMAILID: "" }],
        });
      }
    }
  }, [resAbout]);

  useEffect(() => {
    dispatch({
      type: SIGN_IN,
      payload: localStorage.getItem("user"),
    });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    document.body.classList.add("overflow-hidden");
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);

  if (
    localStorage.getItem("user") &&
    decode(localStorage.getItem("user")).role == "owner"
  ) {
    return <Redirect to={`/${mobileNo}/owner`} />;
  } else if (
    localStorage.getItem("user") &&
    decode(localStorage.getItem("user")).role == "user"
  ) {
    return <Redirect to={`/${mobileNo}`} />;
  } else {
    return (
      <div>
        <ShopModal
          handleChange={(e) => {
            setFormMobileNo(e.target.value);
          }}
          handleClose={handleClose}
          handleSubmit={(e) => {
            e.preventDefault();
            history.push(`/${formMobileNo}/login`);
            setOpen(false);
          }}
          open={open}
          mobileNo={formMobileNo}
        />
        {resAbout ? (
          resAbout.data.result.length == 0 ? (
            <div className="bg-[#7156FA] min-h-screen">
              <div className="flex pt-32">
                <div className="border bg-white border-black p-5 rounded-3xl shadow-xl mx-auto flex flex-col items-center">
                  <SearchIcon className="text-9xl text-gray-400/30" />
                  <div className="text-xl font-bold text-black/80">
                    Oops! No store Found
                  </div>
                  <div className="text-gray-400 mt-2">
                    Please check the store id properly
                  </div>
                  <div
                    className="bg-black text-white rounded-full py-2 px-4 mt-3 cursor-pointer"
                    onClick={handleClickOpen}
                  >
                    Try Again
                  </div>
                </div>
              </div>
            </div>
          ) : width < 700 ? (
            <div className="relative" style={{ height: "100vh" }}>
              <div className="absolute bottom-8 w-full">
                <div className="mx-2 xs:mx-10">
                  {isFetching ? (
                    <CircularProgress className="text-black" size="1.5rem" />
                  ) : (
                    <LoginDrawer />
                  )}
                </div>
              </div>
              <div className="text-center pt-10">
                {globalContext.settings.length != 0 &&
                globalContext.settings.filter(
                  (e) => e.VARIABLE == "customOrderLabel"
                ).length != 0 ? (
                  globalContext.settings.filter(
                    (e) => e.VARIABLE == "customOrderLabel"
                  )[0].VALUE == "Y" ? (
                    <>
                      <img
                        src="/intro_1.png"
                        className="mx-auto w-[300px] h-[300px]"
                        style={{
                          aspectRatio: "1/1",
                        }}
                      />
                      <div className="mt-4 font-semibold text-black">
                        Welcome to{" "}
                        {globalContext.company &&
                        globalContext.company.length != 0
                          ? globalContext.company[0].COMP_NAME
                          : ""}
                      </div>
                      <div className="mt-4 font-semibold text-2xl text-black">
                        Marketed By<div>Saral Softwares.</div>
                      </div>
                    </>
                  ) : (
                    <>
                      <img
                        src="/intro.png"
                        className="mx-auto w-[300px] h-[300px]"
                        style={{
                          aspectRatio: "1/1",
                        }}
                      />
                      <div className="mt-4 font-semibold text-black">
                        Welcome to{" "}
                        {globalContext.company &&
                        globalContext.company.length != 0
                          ? globalContext.company[0].COMP_NAME
                          : ""}
                      </div>
                      <div className="text-xs w-4/5 mx-auto mt-2">
                        E-Commerce or Electronic Commerce means buying and
                        selling of goods, products or services over the internet
                        E-commerce is also Know as Electronic Commerce.
                      </div>
                      <div className="mt-4 font-semibold text-2xl text-black">
                        Marketed By<div>Saral Softwares.</div>
                      </div>
                    </>
                  )
                ) : null}
              </div>
            </div>
          ) : (
            <Container className="p-0 m-0" maxWidth="100">
              <Grid container alignItems="center">
                <Grid sm={6}>
                  <div className="bg-black/5 h-screen w-100 pt-36 px-3">
                    <div className="md:w-3/4 mx-auto">
                      <p className="uppercase pt-6 text-3xl font-medium text-center sm:text-left pl-2 text-black">
                        Sign In
                      </p>
                      <p className="text-center sm:text-left pl-2">
                        Welcome back! Login into your account.
                      </p>
                      <form className="p-3">
                        <div className="form-group">
                          <div className="font-bold text-black mb-1 text-sm">
                            Phone or Acc Cd*
                          </div>
                          <Input
                            type="text"
                            className="bg-white border rounded-2xl w-full px-2 py-2"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            disableUnderline
                            startAdornment={
                              <InputAdornment>
                                <PermIdentityOutlinedIcon />
                              </InputAdornment>
                            }
                          />
                          {!isUserNameValid ? (
                            <div className="text-red-500 mt-1 text-xs">
                              Please Enter valid Phone or Acc Cd.
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <div className="font-bold text-black mb-1 text-sm">
                            Password*
                          </div>
                          <Input
                            type={isPasswordVisible ? "text" : "password"}
                            className="bg-white border rounded-2xl w-full px-2 py-2"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            disableUnderline
                            startAdornment={
                              <InputAdornment>
                                <LockOutlinedIcon />
                              </InputAdornment>
                            }
                            endAdornment={
                              <div
                                onClick={() => {
                                  setIsPasswordVisible(!isPasswordVisible);
                                }}
                                className="cursor-pointer"
                              >
                                <InputAdornment>
                                  {isPasswordVisible ? (
                                    <VisibilityOutlinedIcon />
                                  ) : (
                                    <VisibilityOffOutlinedIcon />
                                  )}
                                </InputAdornment>
                              </div>
                            }
                          />
                          {!isPasswordValid ? (
                            <div className="text-red-500 mt-1 text-xs">
                              Please Enter valid Password.
                            </div>
                          ) : null}
                        </div>
                        {globalContext.settings.length != 0 &&
                        globalContext.settings ? (
                          globalContext.settings.filter(
                            (e) => e.SETTING_NAME == "SHOW_SIGN_UP"
                          ).length != 0 ? (
                            globalContext.settings.filter(
                              (e) => e.SETTING_NAME == "SHOW_SIGN_UP"
                            )[0].VALUE == "Y" ? (
                              <Link to={`/${params.mobileNo}/signup`}>
                                <div className="float-right mb-2.5 cursor-pointer text-xs">
                                  Don't Have an account?
                                </div>
                              </Link>
                            ) : null
                          ) : null
                        ) : null}
                        <div className="form-group">
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disableElevation
                            className="mb-2 w-full"
                            onClick={handleSubmit}
                            disabled={isLoading || isGuestLoading}
                          >
                            {isLoading ? (
                              <CircularProgress size={24} color="secondary" />
                            ) : (
                              "Login"
                            )}
                          </Button>
                        </div>
                        {globalContext.settings.length != 0 &&
                        globalContext.settings ? (
                          globalContext.settings.filter(
                            (e) => e.SETTING_NAME == "GUEST_LOGIN"
                          )[0].VALUE == "Y" ? (
                            <div className="form-group">
                              <Button
                                variant="outlined"
                                color="primary"
                                type="submit"
                                disableElevation
                                className="mb-2 w-full"
                                onClick={handleGuestLogin}
                                disabled={isLoading || isGuestLoading}
                              >
                                {isGuestLoading ? (
                                  <CircularProgress
                                    size={24}
                                    color="secondary"
                                  />
                                ) : (
                                  "Login as Guest"
                                )}
                              </Button>
                            </div>
                          ) : null
                        ) : null}
                      </form>
                    </div>
                  </div>
                </Grid>
                <Grid sm={6}>
                  <div className="text-center px-3">
                    <div className="mt-4 font-semibold text-black text-3xl">
                      Welcome to{" "}
                      {globalContext.company &&
                      globalContext.company.length != 0
                        ? globalContext.company[0].COMP_NAME
                        : ""}
                    </div>
                    {globalContext.settings.length != 0 &&
                    globalContext.settings.filter(
                      (e) => e.VARIABLE == "customOrderLabel"
                    ).length != 0 ? (
                      globalContext.settings.filter(
                        (e) => e.VARIABLE == "customOrderLabel"
                      )[0].VALUE == "Y" ? (
                        <img
                          src="/intro_1.png"
                          className="mx-auto md:w-[550px] md:h-[550px] w-[350px] h-[350px]"
                          style={{
                            aspectRatio: "1/1",
                          }}
                        />
                      ) : (
                        <>
                          <div className="text-sm md:w-4/6 mx-auto mt-2">
                            E-Commerce or Electronic Commerce means buying and
                            selling of goods, products or services over the
                            internet E-commerce is also Know as Electronic
                            Commerce.
                          </div>
                          <img
                            src="/intro.png"
                            className="mx-auto md:w-[550px] md:h-[550px] w-[350px] h-[350px]"
                            style={{
                              aspectRatio: "1/1",
                            }}
                          />
                        </>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </Grid>
              </Grid>
            </Container>
          )
        ) : (
          <div>
            <div className="grid place-items-center h-screen">
              <CircularProgress className="text-center" />
            </div>
          </div>
        )}
      </div>
    );
  }
};

const ShopModal = ({
  open,
  handleClose,
  mobileNo,
  handleChange,
  handleSubmit,
}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <div className="flex justify-between">
            <div>Go to Shop</div>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={handleClose}
            >
              <span className="ti-close"></span>
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Mobile No</label>
              <input
                type="text"
                className="form-control"
                placeholder="Mobile No"
                value={mobileNo}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-md full-width bg-dark text-light fs-md ft-medium"
                onClick={handleSubmit}
              >
                Login
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Login;
