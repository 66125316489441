import React, { useEffect, useReducer } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import OwnerDashboard from "./Pages/owner";
import Home from "./Pages/user";
import Login from "./Pages/login";
import Logout from "./Pages/logout";
import OwnerViewProducts from "./Pages/owner/Products/OwnerViewProducts";
import GlobalContext from "./Context/globalContext";
import GlobalReducer from "./Context/globalreducer";
import OwnerCategory from "./Pages/owner/Category";
import OwnerOrderDetails from "./Pages/owner/Orders/OrderDetails";
import OwnerViewOrders from "./Pages/owner/Orders/ViewOrders";
import OrderSuccess from "./Pages/user/OrderSucess";
import Shop from "./Pages/user/Shop";
import Products from "./Pages/user/Products";
import AddShop from "./Components/AddShop";
import MainScreen from "./Home";

import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import Category from "./Pages/user/Category";
import OwnerEditProduct from "./Pages/owner/Products/OwnerEditProduct";
import About from "./Pages/user/About";
import Orders from "./Pages/user/Orders/Orders";
import DetailOrder from "./Pages/user/Orders/DetailOrder";
import { decode } from "jsonwebtoken";
import { SWRConfig } from "swr";
import { Instance } from "./Config";
import AdminViewCompany from "./Pages/admin/Company/ViewCompany";
import Search from "./Pages/user/Components/Search";
import AdminViewTheme from "./Pages/admin/Theme/ViewTheme";
import Checkout from "./Pages/user/checkout";
import ProfileInfo from "./Pages/user/profileinfo";
import OwnerUtility from "./Pages/owner/Utility";
import "./index.css";
import OwnerNotification from "./Pages/owner/notification";
import Notification from "./Pages/user/Components/Notification";
import OrdersReport from "./Pages/owner/Reports/orders";
import PartyWiseReport from "./Pages/owner/Reports/partywise";
import CategoryWiseReport from "./Pages/owner/Reports/categorywise";
import StatusWiseReport from "./Pages/owner/Reports/statuswise";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Vendors from "./Pages/user/Vendors";
import CustomSnakBar from "./Components/CustomSnakBar";
import { SIGN_IN } from "./Context/globalActions.types";
import OwnerUserSettings from "./Pages/owner/UserSettings";
import Catalog from "./Pages/user/Catalog";
import OwnerViewCatalog from "./Pages/owner/catalog";
import UserPrivacyPolicy from "./Pages/user/UserPrivacyPolicy";
import SignUp from "./Pages/SignUp";
import ContactUs from "./Pages/user/ContactUs";
import OwnerViewApprovals from "./Pages/owner/approval";

function App() {
  const initalValue = {
    user: "",
    orders: [],
    products: [],
    category: [],
    cart: [],
    snackBar: null,
    productsByCategory: [],
    similarProducts: [],
    settings: [],
    company: [],
    owner: [],
    account: {},
    notification: [],
    accountsSettings: [],
    catalog: [],
  };

  const [globalContext, dispatch] = useReducer(GlobalReducer, initalValue);

  let theme = createTheme({
    typography: {
      fontFamily: ["Inter Var", "Helvetica Neue", "Arial", "sans-serif"].join(
        ","
      ),
    },
    props: {
      MuiListItem: {
        style: { fontFamily: "Inter Var, Helvetica Neue, Arial" },
      },
    },
  });

  if (globalContext.owner && globalContext.owner.length != 0) {
    if (
      window.location.pathname ==
        `/${window.location.href.split("/")[3]}/login` ||
      window.location.pathname ==
        `/${window.location.href.split("/")[3]}/signup` ||
      window.location.pathname ==
        `/${window.location.href.split("/")[3]}/contactus`
    ) {
      if (globalContext.owner[0].COLOR) {
        theme = createTheme({
          palette: {
            primary: {
              main: `${globalContext.owner[0].COLOR.primaryColor} !important`,
              contrastText: `${globalContext.owner[0].COLOR.primaryColorText} !important`,
            },
            secondary: {
              main: `${globalContext.owner[0].COLOR.secondaryColor}`,
              contrastText: `${globalContext.owner[0].COLOR.secondaryColorText} !important`,
            },
          },
          typography: {
            fontFamily: [
              "Inter Var",
              "Helvetica Neue",
              "Arial",
              "sans-serif",
            ].join(","),
          },
          props: {
            MuiListItem: {
              style: { fontFamily: "Inter Var, Helvetica Neue, Arial" },
            },
          },
        });
      }
    }
    if (
      localStorage.getItem("user") &&
      decode(localStorage.getItem("user")).role == "user"
    ) {
      if (globalContext.owner[0].COLOR) {
        theme = createTheme({
          palette: {
            primary: {
              main: `${globalContext.owner[0].COLOR.primaryColor} !important`,
              contrastText: `${globalContext.owner[0].COLOR.primaryColorText} !important`,
            },
            secondary: {
              main: `${globalContext.owner[0].COLOR.secondaryColor}`,
              contrastText: `${globalContext.owner[0].COLOR.secondaryColorText} !important`,
            },
          },
          typography: {
            fontFamily: [
              "Inter Var",
              "Helvetica Neue",
              "Arial",
              "sans-serif",
            ].join(","),
          },
          props: {
            MuiListItem: {
              style: { fontFamily: "Inter Var, Helvetica Neue, Arial" },
            },
          },
        });
      }
    }
  }

  if (!localStorage.getItem("user")) {
    localStorage.setItem("user", "");
  } else {
    if (
      decode(localStorage.getItem("user")).exp <
      new Date().getTime() / 1000
    ) {
      localStorage.setItem("user", "");
    }
  }

  useEffect(() => {
    dispatch({
      payload: localStorage.getItem("user"),
      type: SIGN_IN,
    });
  }, []);

  return (
    <SWRConfig
      value={{
        refreshInterval: 0,
        revalidateOnFocus: false,
        fetcher: (resource, init) =>
          Instance.get(resource, init).then((res) => res),
      }}
    >
      <ThemeProvider theme={theme}>
        <GlobalContext.Provider value={{ globalContext, dispatch }}>
          {globalContext.snackBar ? (
            <CustomSnakBar data={globalContext.snackBar} />
          ) : null}
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router>
              <Switch>
                <Route exact path="/">
                  <MainScreen />
                </Route>
                <Route exact path="/privacy-policy">
                  <PrivacyPolicy />
                </Route>
                <Route exact path="/vendors">
                  <Vendors />
                </Route>
                {/* <Route exact path="/admin">
                  <AdminViewCompany />
                </Route>
                <Route exact path="/admin/theme">
                  <AdminViewTheme />
                </Route> */}
                <Route exact path="/:mobileNo/login">
                  <div className="owner-template pb-0">
                    <Login />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/signup">
                  <div className="owner-template pb-0">
                    <SignUp />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/contactus">
                  <div className="owner-template pb-0">
                    <ContactUs />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/privacy-policy">
                  <div className="user-page theme-1-style">
                    <UserPrivacyPolicy />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/addshop">
                  <div className="user-page theme-1-style">
                    <AddShop />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/logout">
                  <div className="user-page theme-1-style">
                    <Logout />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/orders">
                  <div className="user-page theme-1-style">
                    <Orders />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/order/:orderId">
                  <div className="user-page theme-1-style">
                    <DetailOrder />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/cart/complete">
                  <div className="user-page theme-1-style">
                    <OrderSuccess />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/">
                  <div className="user-page custom-bg-gray theme-1-style">
                    <Home />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/shop">
                  <div className="user-page custom-bg-gray theme-1-style">
                    <Shop />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/checkout">
                  <div className="user-page theme-1-style">
                    <Checkout />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/category">
                  <div className="user-page theme-1-style">
                    <Category />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/about">
                  <div className="user-page theme-1-style">
                    <About />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/profile">
                  <div className="user-page theme-1-style">
                    <ProfileInfo />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/notification">
                  <div className="user-page theme-1-style">
                    <Notification />
                  </div>
                </Route>
                {/* <Route exact path="/:mobileNo/search">
                  <div className="user-page theme-1-style">
                    <Search />
                  </div>
                </Route> */}
                <Route exact path="/:mobileNo/catalog">
                  <div className="user-page theme-1-style">
                    <Catalog />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/product/:ITEM_CD">
                  <div className="user-page theme-1-style">
                    <Products />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/owner">
                  <div className="owner-template">
                    <OwnerDashboard />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/owner/usersettings">
                  <div className="owner-template">
                    <OwnerUserSettings />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/owner/orders">
                  <div className="owner-template">
                    <OwnerViewOrders />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/owner/orderdetail">
                  <div className="owner-template">
                    <OwnerOrderDetails />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/owner/editproduct">
                  <div className="owner-template">
                    <OwnerEditProduct />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/owner/catalog">
                  <div className="owner-template">
                    <OwnerViewCatalog />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/owner/viewproducts">
                  <div className="owner-template">
                    <OwnerViewProducts />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/owner/category">
                  <div className="owner-template">
                    <OwnerCategory />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/owner/utility">
                  <div className="owner-template">
                    <OwnerUtility />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/owner/userapproval">
                  <div className="owner-template">
                    <OwnerViewApprovals />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/owner/notification">
                  <div className="owner-template">
                    <OwnerNotification />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/owner/report/orders">
                  <div className="owner-template">
                    <OrdersReport />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/owner/report/partywise">
                  <div className="owner-template">
                    <PartyWiseReport />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/owner/report/categorywise">
                  <div className="owner-template">
                    <CategoryWiseReport />
                  </div>
                </Route>
                <Route exact path="/:mobileNo/owner/report/statuswise">
                  <div className="owner-template">
                    <StatusWiseReport />
                  </div>
                </Route>
              </Switch>
            </Router>
          </MuiPickersUtilsProvider>
        </GlobalContext.Provider>
      </ThemeProvider>
    </SWRConfig>
  );
}

export default App;
